import React, { useEffect } from "react";
import "./tabs.css";

import BodyMass from "../../../assets/health/body-mass.png"
import IBW from "../../../assets/health/ideal-body-weight.png"
import eGFR from "../../../assets/health/egfr.png"
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useNavigate } from "react-router-dom";
import IncomeImg from "../../../assets/images//income-tax.png";
export default function Tabs2() {
  import("../../pages/retirement/rt.css").then(() => {});
  let navigate = useNavigate();
  const [tabId, setTab] = React.useState(window.location.hash?.slice(2, 100));
  useEffect(() => {
    openCity();
  }, []);

  const openCity = () => {
    setTimeout(() => {
      let ids=document.getElementsByClassName(`${tabId}`);
      console.log("ids[0]",tabId)
      ids[0].className +=" active";
     }, 1); 
  };

  return (
    <>
      <div className="tab d-none d-lg-block mt-5 pt-5">
        <button
          className="tablinks FDCal bmi-calulator"
          data-tooltip-id="bmi"
          onClick={(event) =>{navigate("/bmi-calulator")}}
        >
          <img className="tab-img1" src={BodyMass} />
        </button>
        <ReactTooltip
          id="bmi"
          place="top"
          content="BMI Calculator"
        />
        <button
          className="tablinks TermCal ibw-calulator"
          data-tooltip-id="Term-cal"
          onClick={(event) =>{navigate("/ibw-calulator")}}
        >
          <img className="tab-img1" src={IBW} />
        </button>
        <ReactTooltip
          id="Term-cal"
          place="top"
          content="Ideal Body Weight Calculator"
        />
        <button
          className="tablinks childCal eGFR"
          data-tooltip-id="eGFR"
          onClick={(event) =>{navigate("/eGFR")}}
          >
          <img className="tab-img" src={eGFR} />
        </button>
        <ReactTooltip
          id="eGFR"
          place="top"
          content="eGFR Calculator"
          />
          {/* 
        <button
          className="tablinks ReccuringCal rd-calulator"
          data-tooltip-id="Recurring"
          onClick={(event) =>{navigate("/rd-calulator")}}
        >
          <img className="tab-img" src={Recurring} />
        </button>
        <ReactTooltip
          id="Recurring"
          place="top"
          content="Recurring Deposit Calculator"
        />
        <button
          className="tablinks SIPCal sip-calulator"
          data-tooltip-id="SIP"
          onClick={(event) =>{navigate("/sip-calulator")}}
        >
          <img className="tab-img" src={SIP} />
        </button>
        <ReactTooltip id="SIP" place="top" content="SIP Calculator" />

        <button
          className="tablinks RetirementCal retirment-calulator"
          data-tooltip-id="Retirement"
          onClick={(event) =>{navigate("/retirment-calulator")}}
        >
          <img className="tab-img" src={Ulip} />
        </button>
        <ReactTooltip
          id="Retirement"
          place="top"
          content="Retirement Calculator"
        />
        <button
          className="tablinks PPFCal ppf-calulator"
          data-tooltip-id="PPF"
          onClick={(event) =>{navigate("/ppf-calulator")}}
        >
          <img className="tab-img" src={PPF} />
        </button>
        <ReactTooltip id="PPF" place="top" content="PPF Calculator" />
        <button
          className="tablinks IncomeTaxCal incometax-calulator"
          data-tooltip-id="IncomeTax"
          onClick={(event) =>{navigate("/incometax-calulator")}}
        >
          <img className="tab-img" src={IncomeImg} />
        </button>
        <ReactTooltip
          id="IncomeTax"
          place="top"
          content="IncomeTax Calculator"
        />

        <button
          className="tablinks CagrCal cagr-calulator"
          data-tooltip-id="CagrCal1"
          onClick={(event) =>{navigate("/cagr-calulator")}}
        >
          <img className="tab-img" src={CagrCal} />
        </button>
        <ReactTooltip id="CagrCal1" place="top" content="CAGR Calculator" />
          <button
          className="tablinks FVPMCal future-calulator"
          data-tooltip-id="FVPMCal1"
          onClick={(event) =>{navigate("/future-calulator")}}
        >
          <img className="tab-img" src={FVPMimg} />
        </button>
        <ReactTooltip id="FVPMCal1" place="top" content="Future Value  Present Money Calculator" />

        <button
          className="tablinks PMFVCal present-calulator"
          data-tooltip-id="PMFVCal1"
          onClick={(event) =>{navigate("/present-calulator")}}
        >
          <img className="tab-img" src={PMFVimg} />
        </button>
        <ReactTooltip
          id="PMFVCal1"
          place="top"
          content="Present Value Future Money Calculator"
        />

        <button
          className="tablinks HealthCal medical-calulator medical-calculated medical-speech"
          data-tooltip-id="HealthCal1"
          onClick={(event) =>{navigate("/medical-calulator")}}
        >
          <img className="tab-img" src={health} />
        </button>
        <ReactTooltip
          id="HealthCal1"
          place="top"
          content="Medical Calculator"
        /> */}
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/> <br/>
        <br/>
        <br/> <br/>
        <br/>
        <br/>
      </div>
      
    </>
  );
}
