import React, { useEffect, useState } from "react";
import "./ibw.css"
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Chart from 'react-apexcharts'
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/tabs/tabs";
import Tabs2 from "../../components/tabs/tab2";

export default function IBW(props) {
    const navigate = useNavigate()
    const [height, setHeight] = useState(100)
    const [heightFlag, setHeightFlag] = useState(100)
    const [heightErr, setHeightErr] = useState("")

    const [label, setLabel] = useState("")
    const [labelText, setLabelText] = useState("")

    const [result, setResult] = useState("")
    const [paraplegic, setParaplegic] = useState("No")
    const [gender, setGender] = useState("Male")
    const [frame, setFrame] = useState("Medium")

    useEffect(() => {
        if (height < 100 || height > 210) {
            setHeightFlag(true);
            setHeightErr("Height should be 100 to 210 cm")
        } else {
            setHeightFlag(false);
            setHeightErr("")
        }
    }, [height])

    const CalculateIBW = () => {
        let IBWeight = "";
        let HlfClclton = "";
        let PraplagcYN = "";
        let FrmSzeLMS = "";
        let PrplgcProcess = "";
        let Frmszeprocess = "";
        let ht=parseInt(height)
        if (gender == "Male") {
            if (ht > 152.4) {
                HlfClclton = (0.3161 * 152.4) + (1.0738 * (ht - 152.4));
            } else {
                HlfClclton = (0.3161 * ht);
            }
        } else {
            if (ht < 152.4) {
                HlfClclton = (0.2982 * ht);
            } else {
                HlfClclton = (0.2982 * 152.4) + (1.0738 * (ht - 152.4));
            }
        }
        if (paraplegic == "Yes") {
            PrplgcProcess = (HlfClclton * 10) / 100;
            PraplagcYN = HlfClclton - PrplgcProcess;
        } else {
            PraplagcYN = HlfClclton;
        }
        if (frame == "Small") {
            Frmszeprocess = (PraplagcYN * 10) / 100;
            FrmSzeLMS = PraplagcYN - Frmszeprocess;
            IBWeight = FrmSzeLMS.toFixed(1);
        } else if (frame == "Large") {
            Frmszeprocess = (PraplagcYN * 10) / 100;
            FrmSzeLMS = PraplagcYN + Frmszeprocess;
            IBWeight = FrmSzeLMS.toFixed(1);
        } else {
            FrmSzeLMS = PraplagcYN;
            IBWeight = FrmSzeLMS.toFixed(1);
        }

        if (IBWeight == undefined || isNaN(IBWeight) == true) {
            setResult("");
        } else {
            setResult(IBWeight);
        }
    }
   

    function Example(props) {
        return (
            <div>
                
                {
                    result ?
                        <div className="col-lg-12">
                            <div className="under-wieght mt-4">
                            Ideal Body Weight
                            </div>
                            <div className="under-wieght-value">
                                {result} Kg
                            </div>
                        </div>
                        : "--"
                }

            </div>
        );
    }

    return (
        <div>
            {props?.type == "finance" ? <Tabs /> : <Tabs2 />}
            <div className="custome-row">
                <div className="container mt-5 pt-5">
                    <div className='path-text'> <span className="ta-cursor" onClick={() => {
                        navigate("/")
                    }}>Calculator</span> &gt;IBW Calculator</div>
                    <div className='mt-4 fd-cal-text'>IBW Calculator</div>
                    <div className='row group-background mt-2 '>
                        <div className='col-lg-8 col-12 mt-4'>
                            <div className='background p-4' id="content-sip">
                                <div>

                                    {/* <div className="d-flex">
                <div><b>Imperial</b></div>
                <div>
                <label class="switch">
                <input type="checkbox"/>
                <span class="slider round"></span>
              </label>
                </div>
                <div><b>Metric</b></div>
                 </div> */}

                                </div>
                                <div>
                                    <div className='d-flex justify-content-between '>
                                        <div>Height</div>
                                        <div className="d-flex" style={{ width: 115 }}>
                                            <span>
                                                {heightFlag === true ? <svg data-tooltip-id="invest" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                                            </span>&nbsp;
                                            <ReactTooltip id="invest" place="top" content={heightErr} />
                                            <input style={{ background: heightFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: heightFlag === true ? "#E05776" : '', paddingRight: 1 }} className={`form-control shadow-none sm-fl rd-max1 `} id={`${heightFlag === true ? "fname1" : "fname"}`} value={"cm"} readOnly />
                                            <input onChange={(e) => {
                                                if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                                                    if (e.target.value > 210) {
                                                        setHeight(210);
                                                    } else if (e.target.value == "") { setHeight(0); } else if (e.target.value == 0) { setHeight(0); } else {
                                                        if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                                                            let va = e.target.value.substring(1)
                                                            setHeight(va)
                                                        } else if (height == 0 && e.target.value?.split("")[1] == '0') {
                                                            let va = e.target.value?.split("")[0];
                                                            setHeight(va)
                                                        } else {
                                                            setHeight(e.target.value)
                                                        }
                                                    }
                                                }
                                            }} value={height} maxLength={180} style={{ background: heightFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.1)', color: heightFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right" }} className={`shadow-none form-control sm-bg`} type="text" id={`${heightFlag === true ? "fname1" : "fname"}`} name="fname" />
                                        </div>
                                    </div>
                                    <Slider
                                        min={100}
                                        max={210}
                                        value={height}
                                        onChange={(value) => {
                                            setHeight(value)
                                        }}
                                        step={1}
                                    />

                                </div>
                                <div className="row">
                                    <div className="col-lg-2">
                                        <div id="bmi-res">
                                            Paraplegic
                                        </div>
                                    </div>
                                    <div className="col-lg-4">

                                        <div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions" checked={paraplegic == "Yes"} onChange={(e) => {
                                                    setParaplegic(e.target.value)
                                                }} value={"Yes"} id="Paraplegic" />
                                                <label class="form-check-label my-0 mx-2" for="Paraplegic">Yes</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" checked={paraplegic == "No"} onChange={(e) => {
                                                     setParaplegic(e.target.value)

                                                }} value={"No"} name="inlineRadioOptions" id="Paraplegic2" />
                                                <label class="form-check-label my-0 mx-2" for="Paraplegic2">No</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-lg-2">
                                        <div id="bmi-res">
                                            Gender
                                        </div>
                                    </div>
                                    <div className="col-lg-4">

                                        <div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions1" value={"Male"} id="Male" checked={gender == "Male"} onChange={(e) => {
                                                    setGender(e.target.value)

                                                }} />
                                                <label class="form-check-label my-0 mx-2" for="Male">Male</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" value={"Female"} name="inlineRadioOptions1" id="Male1" checked={gender == "Female"} onChange={(e) => {
                                                    setGender(e.target.value)

                                                }} />
                                                <label class="form-check-label my-0 mx-2" for="Male1">Female</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="row mt-3 pb-3">
                                    <div className="col-lg-2">
                                        <div id="bmi-res">
                                            Frame Size
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" value={"Small"} name="inlineRadioOptions2" checked={frame == "Small"} onChange={(e) => {
                                                    setFrame(e.target.value)
                                                }} id="frame" />
                                                <label class="form-check-label my-0 mx-2" for="frame">Small</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" value={"Medium"} name="inlineRadioOptions2" checked={frame == "Medium"} onChange={(e) => {
                                                    setFrame(e.target.value)

                                                }} id="frame1" />
                                                <label class="form-check-label my-0 mx-2" for="frame1">Medium</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" value={"Large"} checked={frame == "Large"} onChange={(e) => {
                                                    setFrame(e.target.value)
                                                }} name="inlineRadioOptions2" id="frame3" />
                                                <label class="form-check-label my-0 mx-2" for="frame3">Large</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-2 col-12 ">
                                    <button
                                        onClick={CalculateIBW}
                                        className="btn btn-danger"
                                    >
                                        Calculate
                                    </button>
                                </div>
                                <div className="col-lg-12 col-12 d-none d-lg-block"><br /><br /><br />
                                    <div className="">
                                        <span style={{ marginLeft: 10 }}>
                                            <strong>FAQs</strong>
                                        </span>
                                    </div>
                                    <div class="accordion mt-0 mb-5" id="FDaccordionExample1">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="FDheadingOne">
                                                <button
                                                    class="accordion-button collapsed px-1 shadow-none"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#FDcollapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="FDcollapseOne"
                                                >
                                                    &nbsp;&nbsp;What is Fixed Deposit?
                                                </button>
                                            </h2>
                                            <div
                                                id="FDcollapseOne"
                                                class="accordion-collapse collapse"
                                                aria-labelledby="FDheadingOne"
                                                data-bs-parent="#FDaccordionExample1"
                                            >
                                                <div class="accordion-body px-1">
                                                    <>
                                                        <p id="isPasted">
                                                            FDs are a form of fixed-income investment where you deposit a lump sum amount with a bank or
                                                            financial institution, and they pay you a predetermined interest rate over a fixed period.
                                                        </p>
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item " id="FDaccordionExample1">
                                            <h2 class="accordion-header" id="FDheadingTwo">
                                                <button
                                                    class="accordion-button collapsed px-1 shadow-none"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#FDcollapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="FDcollapseTwo"
                                                >
                                                    &nbsp; Products that offer similar + added benefits.
                                                </button>
                                            </h2>
                                            <div
                                                id="FDcollapseTwo"
                                                class="accordion-collapse collapse"
                                                aria-labelledby="FDheadingTwo"
                                                data-bs-parent="#FDaccordionExample1"
                                            >
                                                <div class="accordion-body px-1">
                                                    <p style={{ textAlign: "left" }} id="isPasted">
                                                        <p>
                                                            In contrast, insurance products are primarily designed to provide protection and risk coverage,
                                                            although some insurance-linked products may have an investment or savings component. That said,
                                                            here are some insurance products with savings or investment features that may offer stability or
                                                            guaranteed returns similar to FDs:<br />
                                                        </p>
                                                        1.<strong>Endowment Plans:</strong> Endowment insurance policies offer
                                                        both insurance coverage and a savings element. These policies provide a lump
                                                        sum payout at maturity, which can be viewed as a
                                                        form of savings. They often come with guaranteed returns and may offer
                                                        bonuses.
                                                        <br /><br />
                                                        2.<strong>Whole Life Insurance with Cash Value:</strong> Certain whole
                                                        life insurance policies accumulate cash value over time. This cash value can
                                                        serve as a long- term savings component. While it may not
                                                        provide fixed returns like FDs, it offers a conservative and stable growth
                                                        option.
                                                        <br /><br />
                                                        3.<strong>Guaranteed Savings Plans:</strong> Some insurance products,
                                                        such as guaranteed savings plans, provide assured returns over a fixed tenure.
                                                        These plans may guarantee a minimum return, offering some
                                                        level of stability.
                                                        <br /><br />
                                                        4.<strong>Single Premium Insurance Policies:</strong> These policies
                                                        allow you to make a lump sum premium payment, and they typically offer an
                                                        insurance coveralong with a savings or
                                                        investment component. Returns may vary based on the policy terms.
                                                        <br /><br />
                                                        5.<strong>Annuity Plans:</strong> Annuity plans, which can be considered
                                                        a form of insurance, provide regular income payouts after a lump sum payment.
                                                        While notexactly like FDs, they can provide
                                                        a steady income stream in retirement. It's essential to understand that the
                                                        returns from insurance-linked savings or investment
                                                        products are often lower than what you might get from FDs. Additionally,
                                                        insurance products may come with charges and fees, and their returns can vary based on factors like market performance
                                                        and policy terms. When considering insurance products with savings or
                                                        investment features, carefully review the policy terms, assess the returns,
                                                        understand the cost structure, and ensure the product aligns with your
                                                        financial goals  and risk tolerance. It's
                                                        also advisable to consult with a financial advisor to make informed decisions
                                                        tailored to your specific needs and objectives.
                                                    </p>

                                                </div>
                                                {/* <div className="pb-5"></div> */}
                                            </div>
                                            {/* <div className="pb-5"></div> */}
                                        </div>
                                        <div class="accordion-item" id="FDaccordionExample1">
                                            <h2 class="accordion-header" >
                                                <button
                                                    class="accordion-button collapsed px-1 shadow-none"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#FDheadingFour"
                                                    aria-expanded="false"
                                                    aria-controls="FDheadingFour"
                                                >
                                                    &nbsp; How to use Fixed Deposit calculator?
                                                </button>
                                            </h2>
                                            <div
                                                id="FDheadingFour"
                                                class="accordion-collapse collapse"
                                                aria-labelledby="FDheadingFour"
                                                data-bs-parent="#FDaccordionExample1"
                                            >
                                                <div class="accordion-body px-1">
                                                    <strong> To calculate your Fixed Deposit (FD), follow these steps:</strong><br /><br />
                                                    <strong>1.</strong> <strong>Total Investment:</strong> Use the slider or manually enter the amount.<br />
                                                    <strong>2.</strong> <strong>Rate of Interest (p.a):</strong> Use the slider or manually enter the rate.<br />
                                                    <strong>3.</strong> <strong>Time Period Frequency:</strong> Select from Years, Months, or Days via dropdown.<br />
                                                    <strong>4.</strong><strong>Time Period:</strong> Use the slider or manually enter the duration.<br />
                                                    <strong>5.Interest Calculation Frequency:</strong>  Choose Simple Interest, Compounded Monthly,<br />
                                                    Quarterly, Half-Yearly, or Yearly from the dropdown.<br /><br />
                                                    <strong> Note:</strong> Upon completing all required fields, the calculated amounts will display on the
                                                    bottom side of the screen.<br /><br />
                                                    <strong> Additional Functionality:</strong><br />
                                                    <strong>Pie Chart Feature:</strong><br />
                                                    <strong>1.Total Investment:</strong>  Shows the total investment.<br />
                                                    <strong>2.Total Interest:</strong>  Displays the total Interest.<br /><br />
                                                    <strong>Tooltips:</strong><br />

                                                    <strong>1. On PC:</strong> Mouse hover over Total Investment for a tooltip displaying the invested
                                                    amount.<br />
                                                    <strong>2.On PC:</strong>  Mouse hover over Total Return for a tooltip displaying the return amount.<br />
                                                    <strong>3.On Mobile:</strong>  Tap & hold on Total Investment for a tooltip displaying the invested
                                                    amount.<br />
                                                    <strong>4.On Mobile:</strong>  Tap & hold on Total Return for a tooltip displaying the return amount.<br />
                                                    Start planning your Fixed Deposit today!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <hr style={{ border: '2px solid' }} /> */}
                        </div>
                        <div className='col-lg-4 col-12 mt-4 mb-5 pb-5 mb-lg-0'>
                            <div className='background p-4 text-center'>
                                <div className="pt-4"></div>
                                <div className="d-flex justify-content-center">
                                    <div className="d-flex justify-content-center">
                                        <div className="" id="bmi-result" style={{ fontSize: 24 }}>Your ibw result</div>
                                    </div>

                                </div>
                                {<Example />}

                                <div className="col-lg-12 col-12 d-lg-none d-sm-block"><br /><br /><br />
                                    <div className="">
                                        <span style={{ marginLeft: 10 }}>
                                            <strong>FAQs</strong>
                                        </span>
                                    </div>
                                    <div class="accordion mt-0 mb-5" id="FDaccordionExample1">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="FDheadingOne">
                                                <button
                                                    class="accordion-button collapsed px-1 shadow-none"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#FDcollapseOne"
                                                    aria-expanded="true"
                                                    aria-controls="FDcollapseOne"
                                                >
                                                    &nbsp;&nbsp;What is Fixed Deposit?
                                                </button>
                                            </h2>
                                            <div
                                                id="FDcollapseOne"
                                                class="accordion-collapse collapse"
                                                aria-labelledby="FDheadingOne"
                                                data-bs-parent="#FDaccordionExample1"
                                            >
                                                <div class="accordion-body px-1">
                                                    <>
                                                        <p id="isPasted">
                                                            FDs are a form of fixed-income investment where you deposit a lump sum amount with a bank or
                                                            financial institution, and they pay you a predetermined interest rate over a fixed period.
                                                        </p>
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item " id="FDaccordionExample1">
                                            <h2 class="accordion-header" id="FDheadingTwo">
                                                <button
                                                    class="accordion-button collapsed px-1 shadow-none"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#FDcollapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="FDcollapseTwo"
                                                >
                                                    &nbsp; Products that offer similar + added benefits.
                                                </button>
                                            </h2>
                                            <div
                                                id="FDcollapseTwo"
                                                class="accordion-collapse collapse"
                                                aria-labelledby="FDheadingTwo"
                                                data-bs-parent="#FDaccordionExample1"
                                            >
                                                <div class="accordion-body px-1">
                                                    <p style={{ textAlign: "left" }} id="isPasted">
                                                        <p>
                                                            In contrast, insurance products are primarily designed to provide protection and risk coverage,
                                                            although some insurance-linked products may have an investment or savings component. That said,
                                                            here are some insurance products with savings or investment features that may offer stability or
                                                            guaranteed returns similar to FDs:<br />
                                                        </p>
                                                        1.<strong>Endowment Plans:</strong> Endowment insurance policies offer
                                                        both insurance coverage and a savings element. These policies provide a lump
                                                        sum payout at maturity, which can be viewed as a
                                                        form of savings. They often come with guaranteed returns and may offer
                                                        bonuses.
                                                        <br /><br />
                                                        2.<strong>Whole Life Insurance with Cash Value:</strong> Certain whole
                                                        life insurance policies accumulate cash value over time. This cash value can
                                                        serve as a long- term savings component. While it may not
                                                        provide fixed returns like FDs, it offers a conservative and stable growth
                                                        option.
                                                        <br /><br />
                                                        3.<strong>Guaranteed Savings Plans:</strong> Some insurance products,
                                                        such as guaranteed savings plans, provide assured returns over a fixed tenure.
                                                        These plans may guarantee a minimum return, offering some
                                                        level of stability.
                                                        <br /><br />
                                                        4.<strong>Single Premium Insurance Policies:</strong> These policies
                                                        allow you to make a lump sum premium payment, and they typically offer an
                                                        insurance coveralong with a savings or
                                                        investment component. Returns may vary based on the policy terms.
                                                        <br /><br />
                                                        5.<strong>Annuity Plans:</strong> Annuity plans, which can be considered
                                                        a form of insurance, provide regular income payouts after a lump sum payment.
                                                        While notexactly like FDs, they can provide
                                                        a steady income stream in retirement. It's essential to understand that the
                                                        returns from insurance-linked savings or investment
                                                        products are often lower than what you might get from FDs. Additionally,
                                                        insurance products may come with charges and fees, and their returns can vary based on factors like market performance
                                                        and policy terms. When considering insurance products with savings or
                                                        investment features, carefully review the policy terms, assess the returns,
                                                        understand the cost structure, and ensure the product aligns with your
                                                        financial goals  and risk tolerance. It's
                                                        also advisable to consult with a financial advisor to make informed decisions
                                                        tailored to your specific needs and objectives.
                                                    </p>

                                                </div>
                                                {/* <div className="pb-5"></div> */}
                                            </div>
                                            {/* <div className="pb-5"></div> */}
                                        </div>
                                        <div class="accordion-item" id="FDaccordionExample1">
                                            <h2 class="accordion-header" >
                                                <button
                                                    class="accordion-button collapsed px-1 shadow-none"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#FDheadingFour"
                                                    aria-expanded="false"
                                                    aria-controls="FDheadingFour"
                                                >
                                                    &nbsp; How to use Fixed Deposit calculator?
                                                </button>
                                            </h2>
                                            <div
                                                id="FDheadingFour"
                                                class="accordion-collapse collapse"
                                                aria-labelledby="FDheadingFour"
                                                data-bs-parent="#FDaccordionExample1"
                                            >
                                                <div class="accordion-body px-1">
                                                    <strong> To calculate your Fixed Deposit (FD), follow these steps:</strong><br /><br />
                                                    <strong>1.</strong> <strong>Total Investment:</strong> Use the slider or manually enter the amount.<br />
                                                    <strong>2.</strong> <strong>Rate of Interest (p.a):</strong> Use the slider or manually enter the rate.<br />
                                                    <strong>3.</strong> <strong>Time Period Frequency:</strong> Select from Years, Months, or Days via dropdown.<br />
                                                    <strong>4.</strong><strong>Time Period:</strong> Use the slider or manually enter the duration.<br />
                                                    <strong>5.Interest Calculation Frequency:</strong>  Choose Simple Interest, Compounded Monthly,<br />
                                                    Quarterly, Half-Yearly, or Yearly from the dropdown.<br /><br />
                                                    <strong> Note:</strong> Upon completing all required fields, the calculated amounts will display on the
                                                    bottom side of the screen.<br /><br />
                                                    <strong> Additional Functionality:</strong><br />
                                                    <strong>Pie Chart Feature:</strong><br />
                                                    <strong>1.Total Investment:</strong>  Shows the total investment.<br />
                                                    <strong>2.Total Interest:</strong>  Displays the total Interest.<br /><br />
                                                    <strong>Tooltips:</strong><br />

                                                    <strong>1. On PC:</strong> Mouse hover over Total Investment for a tooltip displaying the invested
                                                    amount.<br />
                                                    <strong>2.On PC:</strong>  Mouse hover over Total Return for a tooltip displaying the return amount.<br />
                                                    <strong>3.On Mobile:</strong>  Tap & hold on Total Investment for a tooltip displaying the invested
                                                    amount.<br />
                                                    <strong>4.On Mobile:</strong>  Tap & hold on Total Return for a tooltip displaying the return amount.<br />
                                                    Start planning your Fixed Deposit today!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pb-5"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}