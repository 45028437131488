import React, { useEffect, useState } from "react";
import "./bmi.css"
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Chart from 'react-apexcharts'
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/tabs/tabs";
import Tabs2 from "../../components/tabs/tab2";

export default function BMI(props) {
  const navigate = useNavigate()
  const [weight,setWeight]=useState(35)
  const [height,setHeight]=useState(100)
  const [weightFlag,setWeightFlag]=useState(false)
  const [weightErr,setweightErr]=useState("")
  const [heightFlag,setHeightFlag]=useState(100)
  const [heightErr,setHeightErr]=useState("")

  const [label,setLabel]=useState("")
  const [labelText,setLabelText]=useState("")

  const [result,setResult]=useState("")

  useEffect(()=>{
       if(weight<35 || weight>180){
        setWeightFlag(true);
        setweightErr("Weight should be 35 to 180 kg")
       }else{
        setWeightFlag(false);
        setweightErr("")
       }
  },[weight])

  useEffect(()=>{
    if(height<100 || height>210){
     setHeightFlag(true);
     setHeightErr("Height should be 100 to 210 cm")
    }else{
      setHeightFlag(false);
      setHeightErr("")
    }
},[height])

const CalculateBmi=()=>{
  if(heightFlag==false || weightFlag==false){
    let heightVal = height / 100;
    let calculation = (weight / (heightVal * heightVal)).toFixed(1);
    setResult(calculation)
    if(calculation < 18.5){
      setLabel("BMI < 18.5");
      setLabelText("Underweight")
      return false
    }
    if(calculation>=18.5 && calculation<=24.9){
      setLabel("BMI 18.5 - 24.9");
      setLabelText("Normal Weight")
      return false
    }
    if(calculation>=25 && calculation<=29.9){
      setLabel("BMI 25 - 29.9");
      setLabelText("Overweight")
      return false
    }
    if(calculation>=30 && calculation<=34.9){
      setLabel("BMI 30 - 34.9");
      setLabelText("Obesity Level I")
      return false
    }
    if(calculation>=35 && calculation<=39.9){
      setLabel("BMI 35 - 39.9");
      setLabelText("Obesity Level II")
      return false
    }
    if(calculation>40){
      setLabel("BMI > 40");
      setLabelText("Extreme Obesity")
      return false
    }
  }
}

  function Example(props) {
    return (
      <div>
        {result?<div id="bmi-res"className="mt-4">BMI: {result} kg/m2</div>:<div id="bmi-res"className="mt-4">BMI: -:- kg/m2</div>}
        {
          result?
          <div className="col-lg-12">
          <div className="under-wieght mt-4">
            {label}
          </div>
          <div className="under-wieght-value">
           {labelText}
          </div>
        </div>
          :"--"
        }
       
      </div>
    );
  }

  return (
    <div>
      {props?.type=="finance"? <Tabs/>: <Tabs2/>}
    <div className="custome-row">
      <div className="container mt-5 pt-5">
        <div className='path-text'> <span className="ta-cursor" onClick={() => {
          navigate("/")
        }}>Calculator</span> &gt;BMI Calculator</div>
        <div className='mt-4 fd-cal-text'>BMI Calculator</div>
        <div className='row group-background mt-2 '>
          <div className='col-lg-8 col-12 mt-4'>
            <div className='background p-4' id="content-sip">
              <div>
               
              {/* <div className="d-flex">
                <div><b>Imperial</b></div>
                <div>
                <label class="switch">
                <input type="checkbox"/>
                <span class="slider round"></span>
              </label>
                </div>
                <div><b>Metric</b></div>
                 </div> */}
                <div className='d-flex justify-content-between'>
                  <div>Weight</div>
                  <div className="d-flex" style={{ width: 115 }}>
                    <span>
                      {weightFlag === true ? <svg data-tooltip-id="invest1" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                    </span>&nbsp;
                    <ReactTooltip id="invest1" place="top" content={weightErr} />
                    <input style={{background: weightFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: weightFlag === true ? "#E05776" : '', paddingRight: 1 }} className={`form-control shadow-none sm-fl rd-max1 `} id={`${weightFlag === true ? "fname1" : "fname"}`} value={"kg"} readOnly />
                    <input onChange={(e) => {
                      if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                        if (e.target.value > 180) {
                          setWeight(180);
                        } else if (e.target.value == "") { setWeight(0); } else if (e.target.value == 0) { setWeight(0); } else {
                          if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                            let va = e.target.value.substring(1)
                            setWeight(va)
                          } else if (weight == 0 && e.target.value?.split("")[1] == '0') {
                            let va = e.target.value?.split("")[0];
                            setWeight(va)
                          } else {
                            setWeight(e.target.value)
                          }
                        }
                      }
                    }} value={weight} maxLength={180} style={{ background: weightFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.1)', color: weightFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right" }} className={`shadow-none form-control sm-bg`} type="text" id={`${weightFlag === true ? "fname1" : "fname"}`} name="fname" />
                  </div>
                </div>
                <Slider
                  min={35}
                  max={180}
                  value={weight}
                  onChange={(value) => {
                    setWeight(value)
                  }}
                />
              </div>
              <div>
                <div className='d-flex justify-content-between '>
                  <div>Height</div>
                  <div className="d-flex" style={{ width: 115 }}>
                    <span>
                      {heightFlag === true ? <svg data-tooltip-id="invest" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                    </span>&nbsp;
                    <ReactTooltip id="invest" place="top" content={heightErr} />
                    <input style={{background: heightFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: heightFlag === true ? "#E05776" : '', paddingRight: 1 }} className={`form-control shadow-none sm-fl rd-max1 `} id={`${heightFlag === true ? "fname1" : "fname"}`} value={"cm"} readOnly />
                    <input onChange={(e) => {
                      if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                        if (e.target.value > 210) {
                          setHeight(210);
                        } else if (e.target.value == "") { setHeight(0); } else if (e.target.value == 0) { setHeight(0); } else {
                          if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                            let va = e.target.value.substring(1)
                            setHeight(va)
                          } else if (weight == 0 && e.target.value?.split("")[1] == '0') {
                            let va = e.target.value?.split("")[0];
                            setHeight(va)
                          } else {
                            setHeight(e.target.value)
                          }
                        }
                      }
                    }} value={height} maxLength={180} style={{ background: heightFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.1)', color: heightFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right" }} className={`shadow-none form-control sm-bg`} type="text" id={`${heightFlag === true ? "fname1" : "fname"}`} name="fname" />
                  </div>
                </div>
                <Slider
                  min={100}
                  max={210}
                  value={height}
                  onChange={(value) => {
                    setHeight(value)
                  }}
                  step={1}
                />
               
              </div>
              <div className="col-lg-2 col-12 ">
                    <button
                        onClick={CalculateBmi}
                        className="btn btn-danger"
                      >
                        Calculate
                      </button>
              </div>
          <div className="col-lg-12 col-12 d-none d-lg-block"><br/><br/><br/>
            <div  className="">
              <span style={{ marginLeft: 10 }}>
                <strong>FAQs</strong>
              </span>
            </div>
            <div class="accordion mt-0 mb-5" id="FDaccordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="FDheadingOne">
                  <button
                    class="accordion-button collapsed px-1 shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FDcollapseOne"
                    aria-expanded="true"
                    aria-controls="FDcollapseOne"
                  >
                    &nbsp;&nbsp;What is Fixed Deposit?
                  </button>
                </h2>
                <div
                  id="FDcollapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="FDheadingOne"
                  data-bs-parent="#FDaccordionExample1"
                >
                  <div class="accordion-body px-1">
                    <>
                      <p id="isPasted">
                        FDs are a form of fixed-income investment where you deposit a lump sum amount with a bank or
                        financial institution, and they pay you a predetermined interest rate over a fixed period.
                      </p>
                    </>
                  </div>
                </div>
              </div>
              <div class="accordion-item " id="FDaccordionExample1">
                <h2 class="accordion-header" id="FDheadingTwo">
                  <button
                    class="accordion-button collapsed px-1 shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FDcollapseTwo"
                    aria-expanded="false"
                    aria-controls="FDcollapseTwo"
                  >
                    &nbsp; Products that offer similar + added benefits.
                  </button>
                </h2>
                <div
                  id="FDcollapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="FDheadingTwo"
                  data-bs-parent="#FDaccordionExample1"
                >
                  <div class="accordion-body px-1">
                    <p style={{ textAlign: "left" }} id="isPasted">
                      <p>
                        In contrast, insurance products are primarily designed to provide protection and risk coverage,
                        although some insurance-linked products may have an investment or savings component. That said,
                        here are some insurance products with savings or investment features that may offer stability or
                        guaranteed returns similar to FDs:<br />
                      </p>
                      1.<strong>Endowment Plans:</strong> Endowment insurance policies offer
                      both insurance coverage and a savings element. These policies provide a lump
                      sum payout at maturity, which can be viewed as a
                      form of savings. They often come with guaranteed returns and may offer
                      bonuses.
                      <br /><br />
                      2.<strong>Whole Life Insurance with Cash Value:</strong> Certain whole
                      life insurance policies accumulate cash value over time. This cash value can
                      serve as a long- term savings component. While it may not
                      provide fixed returns like FDs, it offers a conservative and stable growth
                      option.
                      <br /><br />
                      3.<strong>Guaranteed Savings Plans:</strong> Some insurance products,
                      such as guaranteed savings plans, provide assured returns over a fixed tenure.
                      These plans may guarantee a minimum return, offering some
                      level of stability.
                      <br /><br />
                      4.<strong>Single Premium Insurance Policies:</strong> These policies
                      allow you to make a lump sum premium payment, and they typically offer an
                      insurance coveralong with a savings or
                      investment component. Returns may vary based on the policy terms.
                      <br /><br />
                      5.<strong>Annuity Plans:</strong> Annuity plans, which can be considered
                      a form of insurance, provide regular income payouts after a lump sum payment.
                      While notexactly like FDs, they can provide
                      a steady income stream in retirement. It's essential to understand that the
                      returns from insurance-linked savings or investment
                      products are often lower than what you might get from FDs. Additionally,
                      insurance products may come with charges and fees, and their returns can vary based on factors like market performance
                      and policy terms. When considering insurance products with savings or
                      investment features, carefully review the policy terms, assess the returns,
                      understand the cost structure, and ensure the product aligns with your
                      financial goals  and risk tolerance. It's
                      also advisable to consult with a financial advisor to make informed decisions
                      tailored to your specific needs and objectives.
                    </p>

                  </div>
                  {/* <div className="pb-5"></div> */}
                </div>
                {/* <div className="pb-5"></div> */}
              </div>
              <div class="accordion-item" id="FDaccordionExample1">
                <h2 class="accordion-header" >
                  <button
                    class="accordion-button collapsed px-1 shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FDheadingFour"
                    aria-expanded="false"
                    aria-controls="FDheadingFour"
                  >
                    &nbsp; How to use Fixed Deposit calculator?
                  </button>
                </h2>
                <div
                  id="FDheadingFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="FDheadingFour"
                  data-bs-parent="#FDaccordionExample1"
                >
                  <div class="accordion-body px-1">
                 <strong> To calculate your Fixed Deposit (FD), follow these steps:</strong><br/><br/>
                  <strong>1.</strong> <strong>Total Investment:</strong> Use the slider or manually enter the amount.<br/>
                 <strong>2.</strong> <strong>Rate of Interest (p.a):</strong> Use the slider or manually enter the rate.<br/>
                 <strong>3.</strong> <strong>Time Period Frequency:</strong> Select from Years, Months, or Days via dropdown.<br/>
                 <strong>4.</strong><strong>Time Period:</strong> Use the slider or manually enter the duration.<br/>
                 <strong>5.Interest Calculation Frequency:</strong>  Choose Simple Interest, Compounded Monthly,<br/>
                  Quarterly, Half-Yearly, or Yearly from the dropdown.<br/><br/>
                 <strong> Note:</strong> Upon completing all required fields, the calculated amounts will display on the
                  bottom side of the screen.<br/><br/>
                 <strong> Additional Functionality:</strong><br/>
                 <strong>Pie Chart Feature:</strong><br/>
                 <strong>1.Total Investment:</strong>  Shows the total investment.<br/>
              <strong>2.Total Interest:</strong>  Displays the total Interest.<br/><br/>
              <strong>Tooltips:</strong><br/>
                 
                  <strong>1. On PC:</strong> Mouse hover over Total Investment for a tooltip displaying the invested
                  amount.<br/>
                  <strong>2.On PC:</strong>  Mouse hover over Total Return for a tooltip displaying the return amount.<br/>
                  <strong>3.On Mobile:</strong>  Tap & hold on Total Investment for a tooltip displaying the invested
                  amount.<br/>
                  <strong>4.On Mobile:</strong>  Tap & hold on Total Return for a tooltip displaying the return amount.<br/>
                  Start planning your Fixed Deposit today!
                   </div>
                </div>
              </div>
            </div>
          </div>
            </div>
            {/* <hr style={{ border: '2px solid' }} /> */}
          </div>
          <div className='col-lg-4 col-12 mt-4 mb-5 pb-5 mb-lg-0'>
            <div className='background p-4 text-center'>
              <div className="pt-4"></div>
              <div className="d-flex justify-content-center">
                <div className="d-flex justify-content-center">
                  <div className="" id="bmi-result" style={{fontSize:24}}>Your bmi result</div>
                </div>
                
              </div>
              {<Example />}

              <div className="col-lg-12 col-12 d-lg-none d-sm-block"><br/><br/><br/>
            <div  className="">
              <span style={{ marginLeft: 10 }}>
                <strong>FAQs</strong>
              </span>
            </div>
            <div class="accordion mt-0 mb-5" id="FDaccordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="FDheadingOne">
                  <button
                    class="accordion-button collapsed px-1 shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FDcollapseOne"
                    aria-expanded="true"
                    aria-controls="FDcollapseOne"
                  >
                    &nbsp;&nbsp;What is Fixed Deposit?
                  </button>
                </h2>
                <div
                  id="FDcollapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="FDheadingOne"
                  data-bs-parent="#FDaccordionExample1"
                >
                  <div class="accordion-body px-1">
                    <>
                      <p id="isPasted">
                        FDs are a form of fixed-income investment where you deposit a lump sum amount with a bank or
                        financial institution, and they pay you a predetermined interest rate over a fixed period.
                      </p>
                    </>
                  </div>
                </div>
              </div>
              <div class="accordion-item " id="FDaccordionExample1">
                <h2 class="accordion-header" id="FDheadingTwo">
                  <button
                    class="accordion-button collapsed px-1 shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FDcollapseTwo"
                    aria-expanded="false"
                    aria-controls="FDcollapseTwo"
                  >
                    &nbsp; Products that offer similar + added benefits.
                  </button>
                </h2>
                <div
                  id="FDcollapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="FDheadingTwo"
                  data-bs-parent="#FDaccordionExample1"
                >
                  <div class="accordion-body px-1">
                    <p style={{ textAlign: "left" }} id="isPasted">
                      <p>
                        In contrast, insurance products are primarily designed to provide protection and risk coverage,
                        although some insurance-linked products may have an investment or savings component. That said,
                        here are some insurance products with savings or investment features that may offer stability or
                        guaranteed returns similar to FDs:<br />
                      </p>
                      1.<strong>Endowment Plans:</strong> Endowment insurance policies offer
                      both insurance coverage and a savings element. These policies provide a lump
                      sum payout at maturity, which can be viewed as a
                      form of savings. They often come with guaranteed returns and may offer
                      bonuses.
                      <br /><br />
                      2.<strong>Whole Life Insurance with Cash Value:</strong> Certain whole
                      life insurance policies accumulate cash value over time. This cash value can
                      serve as a long- term savings component. While it may not
                      provide fixed returns like FDs, it offers a conservative and stable growth
                      option.
                      <br /><br />
                      3.<strong>Guaranteed Savings Plans:</strong> Some insurance products,
                      such as guaranteed savings plans, provide assured returns over a fixed tenure.
                      These plans may guarantee a minimum return, offering some
                      level of stability.
                      <br /><br />
                      4.<strong>Single Premium Insurance Policies:</strong> These policies
                      allow you to make a lump sum premium payment, and they typically offer an
                      insurance coveralong with a savings or
                      investment component. Returns may vary based on the policy terms.
                      <br /><br />
                      5.<strong>Annuity Plans:</strong> Annuity plans, which can be considered
                      a form of insurance, provide regular income payouts after a lump sum payment.
                      While notexactly like FDs, they can provide
                      a steady income stream in retirement. It's essential to understand that the
                      returns from insurance-linked savings or investment
                      products are often lower than what you might get from FDs. Additionally,
                      insurance products may come with charges and fees, and their returns can vary based on factors like market performance
                      and policy terms. When considering insurance products with savings or
                      investment features, carefully review the policy terms, assess the returns,
                      understand the cost structure, and ensure the product aligns with your
                      financial goals  and risk tolerance. It's
                      also advisable to consult with a financial advisor to make informed decisions
                      tailored to your specific needs and objectives.
                    </p>

                  </div>
                  {/* <div className="pb-5"></div> */}
                </div>
                {/* <div className="pb-5"></div> */}
              </div>
              <div class="accordion-item" id="FDaccordionExample1">
                <h2 class="accordion-header" >
                  <button
                    class="accordion-button collapsed px-1 shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#FDheadingFour"
                    aria-expanded="false"
                    aria-controls="FDheadingFour"
                  >
                    &nbsp; How to use Fixed Deposit calculator?
                  </button>
                </h2>
                <div
                  id="FDheadingFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="FDheadingFour"
                  data-bs-parent="#FDaccordionExample1"
                >
                  <div class="accordion-body px-1">
                 <strong> To calculate your Fixed Deposit (FD), follow these steps:</strong><br/><br/>
                  <strong>1.</strong> <strong>Total Investment:</strong> Use the slider or manually enter the amount.<br/>
                 <strong>2.</strong> <strong>Rate of Interest (p.a):</strong> Use the slider or manually enter the rate.<br/>
                 <strong>3.</strong> <strong>Time Period Frequency:</strong> Select from Years, Months, or Days via dropdown.<br/>
                 <strong>4.</strong><strong>Time Period:</strong> Use the slider or manually enter the duration.<br/>
                 <strong>5.Interest Calculation Frequency:</strong>  Choose Simple Interest, Compounded Monthly,<br/>
                  Quarterly, Half-Yearly, or Yearly from the dropdown.<br/><br/>
                 <strong> Note:</strong> Upon completing all required fields, the calculated amounts will display on the
                  bottom side of the screen.<br/><br/>
                 <strong> Additional Functionality:</strong><br/>
                 <strong>Pie Chart Feature:</strong><br/>
                 <strong>1.Total Investment:</strong>  Shows the total investment.<br/>
              <strong>2.Total Interest:</strong>  Displays the total Interest.<br/><br/>
              <strong>Tooltips:</strong><br/>
                 
                  <strong>1. On PC:</strong> Mouse hover over Total Investment for a tooltip displaying the invested
                  amount.<br/>
                  <strong>2.On PC:</strong>  Mouse hover over Total Return for a tooltip displaying the return amount.<br/>
                  <strong>3.On Mobile:</strong>  Tap & hold on Total Investment for a tooltip displaying the invested
                  amount.<br/>
                  <strong>4.On Mobile:</strong>  Tap & hold on Total Return for a tooltip displaying the return amount.<br/>
                  Start planning your Fixed Deposit today!
                   </div>
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>         
          <div className="pb-5"></div>
        </div>
      </div>
    </div>
    </div>
  )
}