
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../src/views/components/header/header'
import Tabs from '../src/views/components/tabs/tabs'
import Home from './views/pages/home-calculator/home';
import FDCalculator from './views/pages/FD/fdcalculator';
import Term from './views/pages/term/term';
import ChildEducation from './views/pages/child-education/child-education';
import RDCalculator from './views/pages/RD/rdcalculator';
import SPCalculator from './views/pages/SIP/sipcalculator';
import Retirement from './views/pages/retirement/retirement';
import PPFCalculator from './views/pages/PPF/ppf';
import IncomeTax from './views/pages/income-tax/income-tax';
import CAGRCalculator from './views/pages/CAGR/Cagr';
import MutualFundCal from './views/pages/MutualFund/MutualFund';
import FutureValueCalculator from './views/pages/FutreValuePresentMoney/FutreValuePresentMoney';
import PresentValueFutureMoney from './views/pages/PresentValueFutureMoney/PresentValueFutureMoney';
import Health from './views/pages/Health/Health';
import { Toaster } from 'react-hot-toast';
import Calculated from './views/pages/Health/Calculated';
import CalculatorSpeech from './views/pages/Health/Calculator-speech';
import BMI from './views/pages/BodyMassIndexCalc/BodyMassIndexCalc';
import IBW from './views/pages/ibw/ibw';
import EGFR from './views/pages/NutritionRequirement/nutrition';

function App() {
  return (
    <>
   
      <Header />
      
      <Routes>
      {/* <Tabs/> */}
        <Route exact path='/' element={< Home />}></Route>
      {/* finantial */}
        <Route exact path='/fd-calulator' element={< FDCalculator type="finance" />}></Route>
        <Route exact path='/term-calulator' element={< Term type="finance" />}></Route>
        <Route exact path='/child-calulator' element={< ChildEducation type="finance" />}></Route>
        <Route exact path='/rd-calulator' element={< RDCalculator type="finance" />}></Route>
        <Route exact path='/sip-calulator' element={< SPCalculator type="finance" />}></Route>
        <Route exact path='/retirment-calulator' element={< Retirement type="finance" />}></Route>
        <Route exact path='/ppf-calulator' element={< PPFCalculator type="finance" />}></Route>
        <Route exact path='/incometax-calulator' element={< IncomeTax type="finance" />}></Route>
        <Route exact path='/cagr-calulator' element={< CAGRCalculator type="finance" />}></Route>
        <Route exact path='/mutual-calulator' element={< MutualFundCal type="finance" />}></Route>
        <Route exact path='/future-calulator' element={< FutureValueCalculator type="finance" />}></Route>
        <Route exact path='/present-calulator' element={< PresentValueFutureMoney type="finance" />}></Route>
        <Route exact path='/medical-calulator' element={< Health type="finance" />}></Route>
        <Route exact path='/medical-calculated' element={< Calculated type="finance" />}></Route>
        <Route exact path='/medical-speech' element={< CalculatorSpeech type="finance" />}></Route>
        {/* health */}
        <Route exact path='/bmi-calulator' element={< BMI type="health" />}></Route>
        <Route exact path='/ibw-calulator' element={< IBW type="health" />}></Route>
        <Route exact path='/eGFR' element={<EGFR type="eGFR" />}></Route>
        {/* <Route exact path='/rd-calulator' element={< RDCalculator type="health" />}></Route>
        <Route exact path='/sip-calulator' element={< SPCalculator type="health" />}></Route>
        <Route exact path='/retirment-calulator' element={< Retirement type="health" />}></Route> */}

      </Routes>
    </>
  );
}

export default App;
